<script setup lang="ts">
import { useTranslation } from "i18next-vue";
import * as i18n from '@/lang/utils'
import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhTW from 'vant/es/locale/lang/zh-TW';
// import { zhCN } from "element-plus/es/locale";

const { i18next, t } = useTranslation();
i18n.set(t);
watch(
	() => i18next.language,
	(val: any) => {
        console.log("val", val);
	},
	{ immediate: true }
);

// function getLocaleName() {
// 	switch (i18next.language) {
// 		case 'zh':
// 		case "zh-CN":
// 			return 'zh-CN';
// 		case "en-US":
// 		case "en":
// 			return 'en-US';
// 		case "es":
// 		case "es-AR":
// 		case "es-BO":
// 		case "es-CL":
// 		case "es-ES":
// 		case "es-MX":
// 		case "es-AR":
// 			return 'es';
// 		case "zh-TW":
// 		case "zh-HK":
// 			return 'zh-TW';
// 		default:
// 			return 'en';
// 	}
// }

function getLocale() {
	switch (i18next.language) {
		case "zh-CN":
			return zhCN;
		case "en":
			return enUS;
		case "es":
		case "es-AR":
		case "es-BO":
		case "es-CL":
		case "es-ES":
		case "es-MX":
		case "es-AR":
			return enUS;
		case "zh-TW":
		case "zh-HK":
			return zhTW;
		default:
			return enUS;
	}
}


onMounted(() => {
	Locale.use(i18next.language, getLocale())
})

</script>

<template>
    <router-view v-slot="{ Component }">
        <Suspense>
            <div>
                <component :is="Component" />
            </div>
        </Suspense>
    </router-view>
</template>
